.help {
	position: relative;
	top: 1px;
	z-index: 100;

	display: inline-block;
	width: 20px;
	height: 20px;

	line-height: 20px;
	color: $color-light-gray;
	text-align: center;
	vertical-align: baseline;
	transition: color $transition-timing;

	&:hover {
		.help-popover {
			@extend %popover-open;
		}
	}

	h3 & {
		margin-left: 5px;
	}
}

	.help-black {
		z-index: auto;
		text-align: left;

		&:hover {
			.help-popover-black {
				@extend %popover-open-black;
			}

			& {
				color: $color-primary;
			}
		}
	}

	.help-error {
		z-index: initial;
		color: $color-destructive;

		.help-popover {
			z-index: 9998;
		}
	}

	.help-icon {
		display: inline-block;
		font-size: 16px;
		vertical-align: top;
		pointer-events: none;
	}

		.help-icon-small {
			font-size: 12px;
		}

	.help-popover {
		@extend %popover-closed;

		position: absolute;
		top: 26px;
		left: 10px;

		width: 240px;
		padding: 13px 15px 15px;
		margin-left: -120px;

		font-family: $font-family-sans-serif;
		font-size: $body-font-size;
		font-weight: $font-weight-normal;
		line-height: $body-line-height;
		letter-spacing: 0;
		color: $color-body-text;
		text-align: left;
		text-transform: none;
		white-space: normal;

		transform-origin: center top;
	}

	.help-popover-black {
		top: 23px;
		left: 90px;
		width: auto;
		padding: 10px;

		color: $color-white;
		background-color: $color-body-text;
		border-radius: 10px;
	}

		.help-paragraph {
			margin: 0;

			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			color: inherit;

			& + & {
				margin-top: 10px;
			}
		}
