$popover-inset-top: 10px;
$popover-inset-horizontal: 10px;
$popover-inset-bottom: 10px;

$popover-shadow-offset-top: 5px;
$popover-shadow-offset-horizontal: 8px;
$popover-shadow-offset-bottom: 11px;

$popover-arrow-size: 30px;
$popover-arrow-offset: 10px;
$popover-y-offset: -1px;

@mixin popover-background($top, $right, $bottom, $left, $source: none) {
	border-image: {
		slice: $top $right $bottom $left fill;
		width: #{$top * 1px} #{$right * 1px} #{$bottom * 1px} #{$left * 1px};
		repeat: stretch stretch;
	}

	@if($source != none) {
		border-image-source: url('../img/#{$source}.png?v2');
	}

	@include screen-scale-gte(2) {
		border-image-slice: #{$top * 2} #{$right * 2} #{$bottom * 2} #{$left * 2} fill;

		@if($source != none) {
			border-image-source: url('../img/#{$source}@2x.png?v2');
		}
	}

	@include screen-scale-gte(3) {
		border-image-slice: #{$top * 3} #{$right * 3} #{$bottom * 3} #{$left * 3} fill;

		@if($source != none) {
			border-image-source: url('../img/#{$source}@3x.png?v2');
		}
	}
}

.popover {
	position: relative;
	z-index: 0;

	display: block;
	padding: $popover-inset-top $popover-inset-horizontal $popover-inset-bottom;

	&::before, &::after {
		position: absolute;
		top: $popover-y-offset;
		bottom: 0;
		z-index: -1;
		margin: (-$popover-shadow-offset-top) (-$popover-shadow-offset-horizontal) (-$popover-shadow-offset-bottom);
		content: '';
	}

	&::before {
		left: 0;
		width: $popover-arrow-offset + $popover-shadow-offset-horizontal * 2;

		@include popover-background(10, 0, 18, 13, popover-left);
	}

	&::after {
		right: 0;
		left: $popover-arrow-offset + $popover-shadow-offset-horizontal * 2 + $popover-arrow-size;
		width: auto;

		@include popover-background(10, 13, 18, 0, popover-right);
	}
}

	.popover-arrow {
		position: absolute;
		top: -7px + $popover-y-offset;
		bottom: 0;
		left: $popover-arrow-offset + $popover-shadow-offset-horizontal;
		z-index: -1;

		width: $popover-arrow-size;
		margin: (-$popover-shadow-offset-top) 0 (-$popover-shadow-offset-bottom);
		margin-left: 0;

		@include popover-background(13, 0, 11, 0, popover-arrow-top);
	}

		.popover-arrow-right {
			.popover-arrow {
				right: $popover-arrow-offset + $popover-shadow-offset-horizontal;
				left: auto;
				margin-left: 0;
			}

			&::before {
				right: $popover-arrow-offset + $popover-arrow-size + $popover-shadow-offset-horizontal * 2;
				width: auto;
			}

			&::after {
				right: 0;
				left: auto;
				width: $popover-shadow-offset-horizontal * 2 + $popover-arrow-offset;
			}
		}

		.popover-arrow-center {
			.popover-arrow {
				left: 50%;
				margin-left: -($popover-arrow-size / 2);
			}

			&::before, &::after {
				width: calc(50% - (#{$popover-arrow-size / 2 - $popover-shadow-offset-horizontal}));
			}

			&::after {
				right: 0;
				left: auto;
			}
		}

		.popover-arrow-bottom {
			.popover-arrow {
				top: $popover-y-offset;
				bottom: -7px + $popover-y-offset;

				@include popover-background(5, 0, 19, 0, popover-arrow-bottom);
			}

			&::before, &::after {
				top: $popover-y-offset;
				bottom: $popover-y-offset;
			}
		}

		.popover-arrow-none {
			.popover-arrow {
				display: none;
			}

			&::before, &::after {
				left: 0;
				width: 50%;
			}

			&::after {
				right: 0;
				left: 50%;

				width: auto;
			}
		}
