.pagination-button-title {
	display: inline-block;
	vertical-align: top;
}

.pagination-button-icon {
	display: inline-block;
	font-size: 12px;

	vertical-align: baseline;
}

.pagination-button-title, .pagination-button-icon {
	& + & {
		margin-left: 4px;
	}
}

@import 'pagination/basic';
