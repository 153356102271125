@import 'common';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

input, textarea, select, p {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	user-select: auto;
}

html {
	font-size: $body-font-size;
}

html, body {
	height: 100%;
	background: $color-body-background; // Overscroll header color
}

body {
	padding: $header-height 0 $footer-height;

	font-family: $font-family-sans-serif;
	font-weight: $font-weight-normal;
	line-height: $body-line-height;
	color: $color-body-text;

	// Overscroll footer color
	&:after {
		position: fixed;
		top: 60%;
		right: 0;
		bottom: -40%;
		left: 0;
		z-index: -1;

		background: $color-footer-background;
		content: '';
	}
}

body, .header, .footer {
	min-width: 1024px;
}

.container {
	width: 1024px;
	max-width: none !important;
}

a {
	color: $color-link;
	text-decoration: none;
	transition: color $transition-timing;

	&:hover { color: $color-link-hover; }
	&:active { color: $color-link-hover; }
}

.wrapper {
	position: relative;
	// Forces footer to bottom if page doesn’t take up enough to scroll
	min-height: 100%;
	background: $color-body-background;
}

.main-container {
	padding-top: $header-margin-bottom;
	padding-bottom: $footer-margin-top;
}

.atlwdg-trigger {
	z-index: 9999;
}

.no-transition {
	transition: none !important;
}

.logo-img {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	display: block;
	width: 100%;
	height: 100%;
}

.logo-img-header, .footer-img-logo {
	margin-top: 10px;
	width: 100px;
	height: 40px;
}

.logo-img-header-small {
	margin-top: 0;
	width: 75px;
	height: 30px;
}

.logo-by-triller {
	background-image: url('../img/julius-by-triller.png');
}

.logo-by-triller-white {
	background-image: url('../img/julius-by-triller-white.png');
}

.logo-julius {
	background-image: url('../img/julius-purple.png');
	margin-top: 0;
}

.logo-triller {
	background-image: url('../img/a-triller-company.png');
	margin-top: 0;
	width: 200px;
	height: 30px
}

[is-new='true']::after {
	content: "NEW";
	background-color: $color-triller-purple;
	color: $color-white;
	margin-left: 5px;
	padding: 3px;
	border-radius: 3px;
	font-size: x-small;
	font-weight: bold;
}

[is-beta='true']::after {
	content: "BETA";
	background-color: $color-triller-purple;
	color: $color-white;
	margin-left: 5px;
	padding: 3px;
	border-radius: 3px;
	font-size: x-small;
	font-weight: bold;
}

@import 'app/utilities';
@import 'app/typography';
@import 'app/sections';
@import 'app/block';
@import 'app/buttons';
@import 'app/modal';
@import 'app/avatar';
@import 'app/impersonator';
@import 'app/flex';
@import 'app/footer';
@import 'app/form';
@import 'app/social';
@import 'app/components';
@import 'app/header';
@import 'app/table';
@import 'app/pagination';
@import 'app/help';
