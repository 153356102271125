%popover-closed {
	position: absolute;
	z-index: 100;

	display: block;

	pointer-events: none;
	cursor: default;
	opacity: 0.0;
	transform: scale(0.8) translateY(-10px);
	transition: transform $transition-timing, opacity $transition-timing;
}

%popover-open {
	pointer-events: auto;
	opacity: 1.0;
	transform: none;
}

%popover-open-black {
	opacity: 0.96;
}

%clear-fix {
	@include clearfix;
}
