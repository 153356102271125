.tab-bar {
	display: block;
	margin-top: 30px;

	font-size: 15px;
	border-bottom: 1px solid $color-border;
}

	.tab-bar-items {
		display: block;
	}

		.tab-bar-item {
			display: inline-block;

			font-family: $font-family-header;
			font-size: 10px;
			font-weight: $font-weight-semibold;
			line-height: 11px;
			letter-spacing: 1px;
			text-transform: uppercase;
			vertical-align: top;

			& + & {
				margin-left: 40px;
			}
		}

			.tab-bar-item-icon {
				position: relative;
				top: -1px;

				display: inline-block;
				height: 17px;
				margin-right: 10px;

				font-size: 17px;
				vertical-align: bottom;

				@include screen-scale-gte(2) {
					top: -1.5px;
				}
			}

			.tab-bar-item-link {
				position: relative;
				display: block;
				padding: 0 0 13px;

				line-height: 17px;
				color: $color-body-text;

				&::after {
					position: absolute;
					bottom: 0;
					left: -2px;

					width: calc(100% + 4px);
					height: 3px;

					background: $color-secondary;
					content: '';
					opacity: 0.0;
					transition: opacity $transition-timing;
				}

				&:hover, &.tab-bar-item-link-active {
					color: $color-secondary;
					&::after { opacity: 1.0; }
				}

				&:active { color: $color-secondary; }
			}

				.tab-bar-item-link-active {
					cursor: default;
				}

				@each $name, $color in $colors-social {
					.tab-bar-link-social-#{$name} {
						&::after { background: $color; }
						&:active { color: $color; }
						&:hover, &.tab-bar-item-link-active { color: $color; }
					}
				}
