.action-menu {
	position: relative;
	z-index: 1;

	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;
}

	.action-menu-plain {
		display: block;

		width: auto;
		height: auto;

		line-height: inherit;
		text-align: inherit;
	}

	.action-menu-button {
		z-index: initial;

		width: auto;
		height: 32px;

		line-height: 32px;
		text-align: left;
	}

		.action-menu-button-items {
			margin-top: 3px;
		}

		.action-menu-button-icon {
			display: inline-block;
			margin-left: 6px;
			font-size: 12px;
		}

			.action-menu-button-icon-indicator {
				margin-left: 10px;

				font-size: 10px;
				color: $color-icon-dropdown;
			}

	.action-menu-icon {
		cursor: pointer;
	}

.action-menu-items {
	position: absolute;
	top: 95%;
	left: -18px;
	z-index: 10;
	min-width: 180px;
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;

	font-size: 15px;
	text-align: left;
	pointer-events: none;

	background-color: $color-white;
	border-radius: $border-radius-size;
	opacity: 0;
	transform: translateY(-15px);
	transition: all $transition-timing;

	.action-menu-open & {
		pointer-events: auto;

		opacity: 1;
		transform: none;

		&.action-menu-items-center {
			transform: translateX(-50%);
		}
	}
}

	.action-menu-trigger {
		display: inline-block;
		cursor: pointer;
		transition: color $transition-timing;
	}

		.action-menu-after-trigger {
			display: inline-block;
		}

		.action-menu-trigger-active {
			color: $color-link;
		}

	.action-menu-items-bottom {
		top: auto;
		bottom: 100%;
		transform: translateY(15px);
	}

	.action-menu-items-right {
		right: -18px;
		left: auto;
	}

	.action-menu-items-center {
		left: 50%;
		transform: translateY(-15px) translateX(-50%);
	}

	.action-menu-items-small {
		min-width: 120px;
	}

	.action-menu-item {
		padding: 0 15px 0 12px;

		line-height: 40px;
		white-space: nowrap;

		&:first-child {
			margin-top: 1px;
		}

		.action-menu-items-bottom & {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-top: 1px;
			}
		}

		+ .action-menu-item {
			border-top: 1px solid $color-border-light;
		}
	}

	.action-menu-item-link, .action-menu-item-button {
		display: block;
		color: $color-black;
	}

	.action-menu-item-button {
		width: 100%;
		text-align: left;
	}

	.action-menu-item-icon {
		display: inline-block;
		width: 15px;
		height: 15px;
		margin-right: 10px;

		color: $color-light-gray;
		text-align: center;
		vertical-align: top;
	}
