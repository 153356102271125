.post-media {
	position: relative;
	font-size: 0;
}

	.post-media-content {
		position: relative;
		overflow: hidden;
		border-radius: $block-border-radius;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			background-image: $post-media-overlay;
			content: '';
		}

		.post-media-placeholder &:after {
			content: none;
		}
	}

	.post-media-placeholder-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 240px;

		font-size: initial;
		text-align: center;

		.post-media-placeholder-title {
			font-size: 14px;
		}

		.post-media-placeholder-body {
			padding: 5px 15px;

			font-size: 11px;
			color: $color-form-input-placeholder;
		}
	}

	.post-media-photo-img {
		width: 100%;
		height: auto;
		max-height: 600px;
		min-height: 240px;
		object-fit: cover;
	}

	.post-media-overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		background: rgba($color-body-text, 0.0);
		border-radius: $block-border-radius;
		transition: background $transition-timing;

		&:hover {
			background: rgba($color-body-text, 0.8);

			.post-media-no-caption & {
				background: rgba($color-body-text, 0.0);
			}
		}

		.post-media-placeholder &:hover {
			background: none;
		}
	}

		.post-media-overlay-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

	.post-media-details {
		position: relative;
		z-index: 2;
		padding: 18px 0;

		font-size: $body-font-size;

		border-top: 0;
	}

	.post-media-caption {
		position: relative;

		display: flex;
		flex: 1;
		overflow: hidden;

		color: $color-white;
		text-overflow: ellipsis;

		opacity: 0;
		transition: opacity $transition-timing;
		user-select: none;

		mask-image: linear-gradient(-180deg, $color-black calc(100% - 18px), rgba($color-black, 0) 100%);

		.post-caption {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			padding: 15px 20px;
		}

		.post-media:hover & {
			opacity: 1;
		}

		.post-media-placeholder:hover & {
			opacity: 0;
		}
	}
