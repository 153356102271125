$tooltip-inset-top: 7px;
$tooltip-inset-horizontal: 10px;
$tooltip-inset-bottom: 7px;

$tooltip-shadow-offset-top: 5px;
$tooltip-shadow-offset-horizontal: 8px;
$tooltip-shadow-offset-bottom: 11px;

$tooltip-arrow-size: 30px;
$tooltip-arrow-offset: 10px;
$tooltip-y-offset: -1px;

@mixin tooltip-background($top, $right, $bottom, $left, $source: none) {
	border-image: {
		slice: $top $right $bottom $left fill;
		width: #{$top * 1px} #{$right * 1px} #{$bottom * 1px} #{$left * 1px};
		repeat: stretch stretch;

	}

	@if($source != none) {
		border-image-source: url('../img/#{$source}.png?v2');
	}

	@include screen-scale-gte(2) {
		border-image-slice: #{$top * 2} #{$right * 2} #{$bottom * 2} #{$left * 2} fill;

		@if($source != none) {
			border-image-source: url('../img/#{$source}@2x.png?v2');
		}
	}

	@include screen-scale-gte(3) {
		border-image-slice: #{$top * 3} #{$right * 3} #{$bottom * 3} #{$left * 3} fill;

		@if($source != none) {
			border-image-source: url('../img/#{$source}@3x.png?v2');
		}
	}
}

@mixin tooltip-background-source($source) {
	border-image-source: url('../img/#{$source}.png?v2');
	@include screen-scale-gte(2) { border-image-source: url('../img/#{$source}@2x.png?v2'); }
	@include screen-scale-gte(3) { border-image-source: url('../img/#{$source}@3x.png?v2'); }
}

.tooltip-parent {
	position: relative;

	&:hover {
		.tooltip {
			pointer-events: auto;

			opacity: 1;
			transform: scale(1) translateX(-50%);
		}

		.tooltip-right {
			transform: scale(1);

			&::before {
				right: $tooltip-arrow-offset + $tooltip-arrow-size + $tooltip-shadow-offset-horizontal;
				width: auto;
				margin-right: 0 !important;
			}
		}
	}
}

.tooltip {
	position: absolute;
	bottom: 100%;
	left: 50%;
	z-index: $header-z-index - 1;

	min-width: 78px;
	padding: $tooltip-inset-top $tooltip-inset-horizontal $tooltip-inset-bottom;
	margin-bottom: 6px;

	font-family: $font-family-sans-serif;
	font-size: 14px;
	font-weight: $font-weight-normal;
	line-height: 1em;
	letter-spacing: 1;
	color: $color-body-text;
	text-transform: none;
	white-space: nowrap;
	pointer-events: none;

	background: $color-white;
	border-radius: 15px;

	opacity: 0;
	transform: translateX(-50%) scale(0.6);
	transform-origin: center 125%;
	transition: opacity $transition-timing, transform $transition-timing;

	&::before, &::after {
		position: absolute;
		top: $tooltip-y-offset;
		bottom: $tooltip-y-offset;
		z-index: -1;

		padding: 0;
		margin: (-$tooltip-shadow-offset-top) (-$tooltip-shadow-offset-horizontal) (-$tooltip-shadow-offset-bottom);
		content: '';
	}

	&::before {
		right: 50%;
		left: 0;
		margin-right: $tooltip-arrow-size / 2;

		@include tooltip-background(21, 0, 25, 22, tooltip-left);
	}

	&::after {
		right: 0;
		left: 50%;
		margin-left: $tooltip-arrow-size / 2;

		@include tooltip-background(21, 22, 25, 0, tooltip-right);
	}
}

	.tooltip-dark {
		color: $color-white;

		&::before { @include tooltip-background-source(tooltip-dark-left); }
		&::after { @include tooltip-background-source(tooltip-dark-right); }

		&.tooltip-bottom {
			.tooltip-arrow { @include tooltip-background-source(popover-dark-arrow-top); }
		}
	}

	.tooltip-small {
		min-width: 60px;
		text-align: center;
	}

	.tooltip-arrow {
		position: absolute;
		top: $tooltip-y-offset;
		bottom: -7px + $tooltip-y-offset;
		left: 50%;
		z-index: -1;

		width: $tooltip-arrow-size;
		margin: (-$tooltip-shadow-offset-top) 0 (-$tooltip-shadow-offset-bottom);
		margin-left: -($popover-arrow-size / 2);


		@include popover-background(5, 0, 19, 0, popover-arrow-bottom);

		.tooltip-dark & {
			&::after { @include tooltip-background-source(popover-dark-arrow-bottom); }
		}
	}

	.tooltip-arrow-none {
		.tooltip-arrow {
			display: none;
		}

		&::before {
			margin-right: 0;
		}

		&::after {
			margin-left: 0;
		}
	}

	.tooltip-bottom {
		top: 100%;
		bottom: auto;

		margin-top: 6px;
		margin-bottom: 0;

		transform-origin: center -25%;
		-webkit-text-fill-color: $color-body-text;

		&::before, &::after {
			top: $tooltip-y-offset;
			bottom: 0;
		}

		.tooltip-arrow {
			top: -7px + $popover-y-offset;
			bottom: 0;
			@include popover-background(13, 0, 11, 0, popover-arrow-top);
		}
	}

	.tooltip-right {
		right: 0;
		left: auto;

		transform: scale(0.6);

		.tooltip-arrow {
			right: $tooltip-arrow-offset + $tooltip-shadow-offset-horizontal;
			left: auto;
			margin-left: 0;
		}

		&::before {
			right: $tooltip-arrow-offset + $tooltip-arrow-size + $tooltip-shadow-offset-horizontal * 2;
			width: auto;
		}

		&::after {
			right: 0;
			left: auto;
			width: $tooltip-shadow-offset-horizontal * 2 + $tooltip-arrow-offset;
		}
	}

	.tooltip-title {
		display: block;
		margin-bottom: 2px;

		font-size: 12px;
		color: $color-secondary-text;
		text-align: center;
		text-transform: uppercase;
	}

	.tooltip-items {
		padding: 3px;
	}

		.tooltip-item {
			line-height: 20px;
		}
