.basic-pagination {
	@extend %clear-fix;

	display: block;
	padding-top: 30px;
}

	.basic-pagination-button-previous {
		float: left;
	}

	.basic-pagination-button-next {
		float: right;
	}
