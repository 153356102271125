.component-representative-card {
	& + & {
		margin-top: 20px;
	}
}

	.component-representative-header {
		position: relative;
		display: flex;
		align-items: center;
		max-width: 100%;
		padding: 15px 20px;

		background-color: rgba($color-border, 0.2);
		border-top-right-radius: $border-radius-size;
		border-top-left-radius: $border-radius-size;

		&:last-child {
			border-bottom-right-radius: $border-radius-size;
			border-bottom-left-radius: $border-radius-size;
		}
	}

		.component-representative-header-content {
			display: block;
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
		}

		.component-representative-header-content-title {
			display: block;
			overflow: hidden;

			font-size: 18px;
			line-height: 21px;
			color: $color-body-text;
			text-overflow: ellipsis;
		}

		.component-representative-header-content-subtitle {
			display: flex;
			align-items: center;
			margin-top: 4px;

			font-size: 13px;
			line-height: 15px;
			color: $color-muted;
		}

			.component-representative-header-content-subtitle-text {
				display: block;
				min-width: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.component-representative-header-content-subtitle-agency,
			.component-representative-header-content-subtitle-type {
				display: inline;
				color: inherit;
			}

			.component-representative-header-content-subtitle-help-wrapper {
				display: block;
				flex-grow: 0;
				flex-shrink: 0;
				width: 13px;
				height: 13px;
				margin-left: 3px;
			}

			.component-representative-header-content-subtitle-help {
				position: absolute;
				top: auto;

				display: block;
				width: inherit;
				height: inherit;
				margin-top: 1px;
				color: $color-tertiary-text;

				@include screen-scale-gte(2.0) {
					margin-top: 0.5px;
				}

				.help-icon {
					width: inherit;
					height: inherit;

					font-size: 13px;
					line-height: 13px;
				}

				.help-popover {
					margin-left: -124px;
				}
			}

		.component-representative-card-header-action {
			display: block;
			flex-grow: 0;
			flex-shrink: 0;
			width: 32px;
			height: 32px;
			padding: 0;
			margin-left: 20px;

			font-size: 18px;
			line-height: 32px;
			text-align: center;

			border-radius: 50%;
		}

	.component-representative-card-contact {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		border-top: 1px solid rgba($color-border, 0.75);
	}

		.component-representative-card-contact-icon {
			position: relative;
			top: 1px;

			display: block;
			width: 16px;
			height: 16px;
			margin-right: 8px;

			font-size: 16px;
			line-height: 16px;
			text-align: center;
		}

		.component-representative-card-contact-value {
			display: block;
			flex: 1;
			overflow: hidden;

			color: $color-body-text;
			text-overflow: ellipsis;
		}
