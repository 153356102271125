.block {
	display: block;

	background: $block-background;
	border-radius: $block-border-radius;
	box-shadow: $block-box-shadow;
}

	.block-sidebar-item {
		& + & {
			margin-top: 30px;
		}
	}

	.block-items {
		margin-bottom: 10px;
	}

	.block-item {
		display: block;
		padding: 15px 20px;

		& + & {
			border-top: 1px solid $color-border;
		}
	}

		.block-item-link {
			color: inherit;
		}

		.block-item-avatar {
			margin-right: 15px;
		}

	.block-header {
		height: 40px;
		padding: 0 15px;

		line-height: 38px;

		background: $block-header-background;
		border-bottom: 1px solid $block-header-border;
	}

		.block-header-action {
			float: right;
			font-size: 18px;
			color: $color-muted;
		}

	.block-footer {
		display: block;
		padding: 15px;
		text-align: center;
		box-shadow: inset 0 1px 0 $color-border;
	}

		.block-footer-button {
			display: block;
			padding: 13px 15px;
			margin: -15px;
			font-size: 13px;
		}

.block-content-search {
	position: absolute;
	top: 21px;
	left: 0px;
	right: 0px;
	height: 200px;
	background-color: white;
	opacity: .5;
	z-index: 10001;
}