$image-path: '../img' !default;

@mixin screen-scale-gte($scale) {
	@media
	only screen and (-webkit-min-device-pixel-ratio: $scale),
	only screen and (min-resolution: #{96 * $scale}dpi) {
		@content;
	}
}

$breakpoints: (
	xs: (min: $screen-xs-min, max: $screen-xs-max),
	sm: (min: $screen-sm-min, max: $screen-sm-max),
	md: (min: $screen-md-min, max: $screen-md-max),
	lg: (min: $screen-lg-min, max: 10000000px)
);

@function expand-width($width, $max: true) {
	@if $width == xs or $width == sm or $width == md or $width == lg {
		@if $max {
			@return map-get(map-get($breakpoints, $width), max);
		} @else {
			@return map-get(map-get($breakpoints, $width), min);
		}
	} @else {
		@return $width;
	}
}

@mixin viewport-lte($width) {
	@media(max-width: expand-width($width, true)) {
		@content;
	}
}

@mixin viewport-gte($width) {
	@media(min-width: expand-width($width, false)) {
		@content;
	}
}

@mixin viewport-between($minimum-width, $maximum-width) {
	@if $minimum-width == xs and $maximum-width == xs {
		@include viewport-lte(xs) {
			@content;
		}
	} @else if $minimum-width == lg and $maximum-width == lg {
		@include viewport-gte(lg) {
			@content;
		}
	} @else {
		@media(min-width: expand-width($minimum-width, false)) and (max-width: expand-width($maximum-width, true)) {
			@content;
		}
	}
}

@mixin viewport-eq($size) {
	@include viewport-between($size, $size) {
		@content;
	}
}

@mixin vector-background-image($size, $name, $horizontal-align: center, $vertical-align: top, $color: transparent) {
	background: $color url(#{$image-path}/#{$name}.png) no-repeat $horizontal-align $vertical-align;

	@if $size != false {
		background-size: $size;
	}

	@include screen-scale-gte(2) {
		background-image: url(#{$image-path}/#{$name}.svg);
	}
}

@mixin link-color($base-color, $property: color, $mode: 'darken', $amount: 5%, $multiplier: 2) {
	#{$property}: $base-color;
	transition: $property $transition-timing;
	@content;

	@if($mode == 'lighten') {
		&:hover {
			#{$property}: lighten($base-color, $amount);
			@content;
		}

		&:active {
			#{$property}: lighten($base-color, $amount * $multiplier);
			@content;
		}
	} @else {
		&:hover {
			#{$property}: darken($base-color, $amount);
			@content;
		}

		&:active {
			#{$property}: darken($base-color, $amount * $multiplier);
			@content;
		}
	}
}

@mixin inner-padding-top($size) {
	&::before {
		display: block;
		height: $size;
		content: '';
	}
}

@mixin avatar-style($size: 100px) {
	width: $size;
	height: $size;

	background: $color-avatar-background none no-repeat center center;
	background-size: cover;
	border-radius: 50%;
	box-shadow: 0 1px 2px 0 rgba($color-black, 0.20);
}
