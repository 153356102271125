$color-primary: #00bdde;
$color-primary-purple: #705dfb;
$color-triller-purple: #705cff;
$color-secondary: #6554b4;
$color-secondary-dark: #24274e;
$color-muted: #7b7f88;
$color-muted-dark: #656972;
$color-card: #fdfdfd;
$color-card-dark: #e2e3e4;
$color-card-purple: #F2F1F9;
$color-card-negative: #F2EEEE;
$color-light-gray: #b8bcc4;
$color-dark-gray: #42454b;
$color-gray: #181717;
$color-darker-gray: #35383e;
$color-form-input-placeholder: #7f8287;
$color-highlighted: #f8feff;
$color-white: #fff;
$color-black: #000;
$color-overlay-background: #2d3435;
$color-row-selected-background: #f8fcfd;

$color-state-positive: #2ec456;
$color-state-neutral: #448fd5;
$color-state-warning: #e58b5a;
$color-state-negative: #de0000;
$color-state-success: #31A473;

$color-background-warning: #dd6338;
$color-background-warning-light: #fae5de;

$color-destructive: $color-state-negative;
$color-affirmative: $color-state-positive;

$color-body-text: #202123;
$color-body-background: #f9fafb;
$color-modal-background: #2d3334;
$color-tooltip-background: #3d3e43;
$color-footer-background: $color-darker-gray;
$color-avatar-background: $color-card-dark;

$color-link: $color-primary;
$color-link-hover: darken($color-link, 5%);
$color-link-active: darken($color-link, 10%);

$color-header-background: $color-white;
$color-navigation-bar-separator: #dbdde1;

$color-border: $color-card-dark;
$color-border-secondary: #f1f2f5;
$color-border-light: #eef0f3;
$color-secondary-text: $color-muted;
$color-tertiary-text: #a3aab7;

$color-difference-positive: $color-state-positive;
$color-difference-negative: #c75849;

$color-icon-inactive: #cfd3d8;
$color-icon-dropdown: #babdc1;
$color-icon-dropdown-light: #d0d3d6;
$color-icon-non-vetted: #e3e3e8;

$color-input-muted: #f4f5f8;

$color-overlay-fade: linear-gradient(-180deg, rgba($color-darker-gray, 0.00) 69%, rgba(#25272b, 0.75) 92%, #202224 100%); // sass-lint:disable-line no-color-literals

$color-post-media-overlay: #171819;

$color-dashed-grid-light: #f2f2f2;
$color-dashed-grid-dark: #e7e7e7;
$color-theme-age: #d6d6d6;

@import 'colors/social';
@import 'colors/charts';
@import 'colors/influencer-status';
@import 'colors/campaign-status';
@import 'colors/list-status';
