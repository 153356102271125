$help-popover-border-color: rgba($color-muted, 0.1);
$help-popover-muted-color: #bdbfc3;

.header-help-popover {
	position: absolute;
	top: 100%;
	right: -13px;
	z-index: 1;

	width: auto;
	padding: 0 30px;
	margin-top: -4px;

	font-size: $body-font-size;
	line-height: $body-line-height;
	text-align: left;

	pointer-events: none;
	cursor: auto;
	opacity: 0;
	transform: translateY(-15px);
	transition: opacity $transition-timing, transform $transition-timing;

	.header-help-active & {
		pointer-events: auto;
		opacity: 1;
		transform: none;
	}
}

	.header-help-popover-placeholder {
		display: block;
		font-size: 18px;
		font-weight: $font-weight-thin;
		text-align: center;
	}

.header-help-popover-section-title-icon {
	position: relative;
	top: -1px;
	display: inline-block;
	margin-right: 15px;

	font-size: 16px;
	color: $help-popover-muted-color;
	vertical-align: middle;
}

.header-help-popover-type {
	color: $color-light-gray;
}

.header-help-popover-links {
	position: relative;

	display: flex;
	margin: 0 -30px;

	font-size: 0;
}

	.header-help-popover-link-section {
		min-width: 320px;
		padding: 30px;
		font-size: $body-font-size;
		vertical-align: top;

		& + & {
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;

				width: 1px;

				background: $help-popover-border-color;
				content: '';
			}
		}
	}

.header-help-popover-link-item {
	position: relative;
	display: block;
	padding-left: 15px + 12px;
	color: $color-dark-gray;

	& + & {
		margin-top: 15px;
	}
}

	.header-help-popover-link-icon {
		position: absolute;
		top: 4px;
		left: 0;

		font-size: 12px;
		color: $help-popover-muted-color;
		vertical-align: baseline;
	}

.header-help-popover-on-demand-empty {
	display: none;
}

.header-help-popover-on-demand {
	overflow: hidden;
}

	.header-help-popover-on-demand-item {
		display: block;
		padding: 20px;
		transition: background $transition-timing;

		& + & {
			border-top: 1px solid rgba($color-black, 0.05);
		}

		&:hover {
			background: $color-body-background;
		}
	}

	.header-help-popover-on-demand-title {
		color: $color-dark-gray;
	}

.header-help-popover-footer {
	display: block;
	padding: 20px 30px;
	margin: 0 -30px;

	text-align: right;

	border-top: 1px solid $help-popover-border-color;
}

@import 'help/search';
@import 'help/videos';
