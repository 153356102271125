.picker-bar {
	position: relative;
	z-index: 100;

	display: block;
	margin-bottom: 30px;
}

	.picker-bar-sorting {
		float: right;
		height: 32px;

		line-height: 32px;
	}

.picker {
	position: relative;
	display: inline-block;
	height: 32px;
	min-width: 200px;
	overflow: hidden;

	font-size: $body-font-size;
	color: $color-muted;
	text-align: left;
	cursor: pointer;

	& + & {
		margin-left: 20px;
	}
}

	.picker-animating {
		overflow: visible;
	}

	.picker-content {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		padding: 0 15px;

		line-height: 30px;

		background: $color-card;
		border: 1px solid $color-border;
		border-radius: $border-radius-size;
	}

	.picker-icon {
		float: right;
		font-size: 10px;
		color: $color-dark-gray;
	}

	.picker-menu {
		position: absolute;
		top: 100%;
		right: -13px;
		left: 13px;

		padding: 0;
		margin-top: 4px;

		clear: both;
		pointer-events: none;

		opacity: 0;
		transform: translateY(-15px);
		transition: opacity $transition-timing, transform $transition-timing, height 0 $transition-duration;

		.picker-open & {
			pointer-events: auto;

			opacity: 1;
			transform: none;
		}
	}

		.picker-menu-item {
			position: relative;

			display: block;
			height: 34px;
			padding: 0 40px 0 15px;

			line-height: 34px;
			text-overflow: ellipsis;
			white-space: nowrap;

			cursor: pointer;

			& + & {
				box-shadow: inset 0 1px 0 $color-border;
			}

			&:hover {
				.picker-menu-item-label {
					color: $color-secondary;
				}
			}
		}

			.picker-menu-item-checkbox {
				position: absolute;
				top: 0;
				right: 15px;

				font-size: 10px;

				opacity: 0;

				transition: opacity $transition-timing, color $transition-timing;
			}

				.picker-menu-item-checkbox-multiple {
					color: $text-muted;
					opacity: 0.25;
				}

			.picker-menu-item-label {
				display: block;
				overflow: hidden;

				text-overflow: ellipsis;
				white-space: nowrap;

				transition: color $transition-timing;
			}

			.picker-menu-item-input {
				display: none;

				&:checked {
					+ .picker-menu-item-label {
						color: $color-secondary;

						+ .picker-menu-item-checkbox {
							color: $color-secondary;
							opacity: 1;
						}
					}
				}
			}
