.avatar {
	position: relative;

	display: block;
	width: $avatar-size;
	height: $avatar-size;
	line-height: $avatar-size;

	background: $color-white;
	border-radius: 50%;
	box-shadow: 0 1px 2px 0 rgba($color-black, 0.20);
}

	.avatar-small {
		width: $avatar-size-small;
		height: $avatar-size-small;
		line-height: $avatar-size-small;
	}

	.avatar-large {
		width: $avatar-size-large;
		height: $avatar-size-large;
		line-height: $avatar-size-large;
	}

	.avatar-xl {
		width: $avatar-size-xl;
		height: $avatar-size-xl;
		line-height: $avatar-size-xl;
	}

	.avatar-plain {
		box-shadow: none;
	}

	.avatar-inline {
		display: inline-block;
		vertical-align: middle;
	}

	%avatar-content {
		display: block;
		width: inherit;
		height: inherit;

		background: $color-avatar-background none no-repeat center center;
		border-radius: inherit;
	}

	.avatar-image {
		@extend %avatar-content;

		background-size: cover;
	}

	.avatar-placeholder {
		@extend %avatar-content;

		font-size: 14px;
		font-weight: $font-weight-bold;
		line-height: inherit;
		color: darken($color-avatar-background, 60%);
		text-align: center;

		opacity: 0.5;

		.avatar-small & {
			font-size: 13px;
		}

		.avatar-large & {
			font-size: 16px;
		}

		.avatar-xl & {
			font-size: 20px;
		}

		.avatar-tile & {
			position: relative;
			top: 50%;
			height: 50px;
			margin-top: -25px;
			font-size: 50px;
		}
	}

	.avatar-gravatar {
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: inherit;
		height: inherit;

		background: transparent none no-repeat center center;
		background-size: cover;
		border-radius: inherit;
	}

	.avatar-status {
		margin: 4px;
	}

		@each $name, $color in $colors-influencer-status {
			.avatar-status-#{$name} {
				box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color;
			}
		}

	.avatar-show-count {
		position: absolute;
		top: 0;
		width: 30px;
		height: 30px;

		font-size: 14px;
		font-weight: $font-weight-semibold;
		color: $color-white;
		text-align: center;

		background-color: rgba($color-dark-gray, 0.7);
		border-radius: 15px;
	}
