$color-social-blog: $color-primary;
$color-social-facebook: #3b5998;
$color-social-twitter: #000000;
$color-social-twitter-footer-hover: #ffffff;
$color-social-instagram: #eb4a6c;
$color-social-youtube: #ff0000;
$color-social-snapchat: #f2d908;
$color-social-vine: #00b488;
$color-social-medium: #00ab6b;
$color-social-linkedin: #0077b5;
$color-social-pinterest: #bd081c;
$color-social-musically: #ff0f50;
$color-social-tiktok: #010101;
$color-social-twitch: #6441a5;

$colors-social: (
	blog: $color-social-blog,
	facebook: $color-social-facebook,
	twitter: $color-social-twitter,
	instagram: $color-social-instagram,
	youtube: $color-social-youtube,
	snapchat: $color-social-snapchat,
	vine: $color-social-vine,
	medium: $color-social-medium,
	linkedin: $color-social-linkedin,
	pinterest: $color-social-pinterest,
	musically: $color-social-musically,
	tiktok: $color-social-tiktok,
	twitch: $color-social-twitch,
	overall: $color-primary
);

$color-social-instagram-gradient: linear-gradient(#7a4dbf 0%, #f73973 54%, #fdb351 100%); // sass-lint:disable-line no-color-literals

$action-type-colors: (
	applause: #4ac87e,
	conversation: $color-primary,
	amplification: $color-secondary
);

.social-icon-unselected {
	color: $color-light-gray;
	-webkit-text-fill-color: inherit;
}
