.header-help-popover-search {
	margin-bottom: 20px;
}

	.header-help-search-input {
		padding-left: 38px;
		box-sizing: border-box !important;

		&:focus ~ .form-search-clear {
			color: $color-card-dark;
			pointer-events: auto;
			opacity: 1.0;

			&:hover {
				color: $color-icon-inactive;
			}
		}

		&:valid ~ .form-search-clear {
			pointer-events: auto;
			opacity: 1.0;
		}
	}
