$dialog-padding-y: 20px;
$dialog-header-padding-x: 20px;
$dialog-padding-x: 30px;
$modal-close-size: 10px;

$modal-alert-error-color-background: $color-background-warning;
$modal-alert-error-color-accent: #ffcbaa;

$modal-alert-success-color-background: $color-secondary-dark;
$modal-alert-success-color-accent: #8183a0;

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $header-z-index + 1;
	display: none;

	width: 100%;
	height: 100%;
	overflow: scroll;

	background: rgba($color-modal-background, 0);
	transition: background $transition-timing;

	&.modal-active {
		background: rgba($color-modal-background, 0.8);
	}
}

	.modal-open {
		overflow: hidden;
	}

	.modal-section {
		& + & {
			padding: 20px $dialog-padding-x 0;
			margin: 20px (-$dialog-padding-x) 0;
			border-top: 1px solid $color-border;
		}
	}

		.modal-section-filled {
			background: $color-body-background;
		}

		.modal-section-footer {
			&, .modal-section + & {
				padding-bottom: 20px;
				margin-bottom: -30px;
			}
		}

	.modal-dialog {
		display: block;
		width: 90%;
		max-width: 420px;

		padding: $dialog-padding-y $dialog-padding-x;
		margin: 120px auto 60px;

		background: $color-white;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;

		opacity: 0;
		transform: scale(0.8);
		box-shadow: 0 0 0 1px rgba($color-black, 0.03), 0 1px 4px 0 rgba($color-black, 0.10), 0 6px 15px 0 rgba($color-black, 0.16);
		transition: transform $transition-timing, opacity $transition-timing;

		&.modal-dialog-active {
			opacity: 1;
			transform: scale(1);
		}
	}

		.modal-dialog-large {
			max-width: 600px;
		}

		.modal-dialog-extra-large {
			max-width: 650px;
		}

		.modal-dialog-form {
			background: $color-body-background;

			.modal-footer {
				padding-bottom: $dialog-padding-y;
				margin-bottom: - $dialog-padding-y;

				background: $color-white;
				border-bottom-right-radius: $border-radius-size;
				border-bottom-left-radius: $border-radius-size;
			}
		}

		.modal-header {
			position: relative;
			height: 60px;
			padding: 0 ($modal-close-size + $dialog-header-padding-x * 2) 0 $dialog-header-padding-x;
			margin: -80px (-$dialog-padding-x) 20px;

			line-height: 62px;
			color: $color-white;

			background: $color-primary;
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
		}

			.modal-header-affirmative {
				background: $color-affirmative;
			}

			.modal-header-destructive {
				background: $color-destructive;
			}

		.modal-title {
			margin: 0;
			overflow: hidden;

			font-size: 18px;
			font-weight: $font-weight-semibold;
			color: $color-white;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.modal-close {
			position: absolute;
			top: 26px;
			right: $dialog-header-padding-x;

			font-size: $modal-close-size;
			line-height: $modal-close-size;
			color: $color-white;

			&:hover {
				color: $color-white;
			}
		}

	.modal-footer {
		@extend %clear-fix;

		padding: 20px $dialog-padding-x 0;
		margin: 30px (-$dialog-padding-x) 0;

		border-top: 1px solid $color-card-dark;
	}

		.modal-footer-close { color: $color-light-gray; }

		.modal-footer-left { float: left; }
		.modal-footer-right { float: right; }

		.modal-footer-attachments-list {
			display: inline-block;
			vertical-align: top;
		}


.modal-alert {
	position: relative;

	display: flex;
	align-items: center;
	padding: 10px 15px 10px 14px;
	margin-bottom: 20px;

	font-size: 15px;

	&::before {
		display: block;
		width: 22px;
		height: 22px;

		font-weight: 400;
		line-height: 20px;
		text-align: center;

		border: 2px solid rgba($color-white, 0.2);
		border-radius: 50%;
		content: '';
	}
}

	.modal-alert-content {
		display: block;
		margin-left: 14px;

		line-height: 20px;
	}

	.modal-alert-error {
		color: $color-white;
		background: $modal-alert-error-color-background;

		&::before {
			font-family: $font-family-icons;
			font-size: 22px;
			line-height: 22px;
			color: $modal-alert-error-color-accent;

			border: 0;
			content: $app-icon-warning-circled;
		}
	}

	.modal-alert-success {
		color: $color-white;
		background: $modal-alert-success-color-background;

		&::before {
			font-family: $font-family-icons;
			font-size: 9px;
			border-color: $modal-alert-success-color-accent;
			content: $app-icon-checkmark;
		}
	}

	.modal-alert-title {
		display: block;
		margin: 0;

		font-weight: $font-weight-semibold;
		color: inherit;
	}

	.modal-alert-message {
		display: block;
		font-size: 13px;

		.modal-alert-error & { color: $modal-alert-error-color-accent; }
		.modal-alert-success & { color: $modal-alert-success-color-accent; }
	}

	.modal-alert-close {
		position: absolute;
		top: 5px;
		right: 5px;

		display: block;
		width: 16px;
		height: 16px;

		line-height: 16px;
		text-align: center;
		cursor: pointer;

		background: rgba($color-white, 0.2);
		border-radius: 50%;
	}

		.modal-alert-close-icon {
			position: relative;
			@include screen-scale-gte(2) { top: -0.5px; }

			display: inline-block;
			width: 8px;
			height: 8px;

			font-size: 8px;
			line-height: 8px;
			color: $color-white;
			vertical-align: middle;

			pointer-events: none;
		}

@import 'modal/export';
