$form-tags-tag-height: 21px;
$form-tags-tag-hot-selector: '.taggle_hot';

.form-tags {
	@extend %form-input;

	position: relative;
	top: 0;
	left: 0;

	height: auto;
	min-height: 33px;

	padding: 0 15px;
	cursor: text;
}

	.form-tags-active {
		border-color: $color-primary;
	}

	.form-tags-placeholder {
		position: absolute;
		top: 5px;
		left: 15px;
		z-index: 2;

		display: block;
		height: 21px;

		line-height: 21px;
		color: $color-form-input-placeholder;
		text-indent: 2px;

		pointer-events: none;
	}

	.form-tags-list {
		position: relative;
		z-index: 1;

		display: block;
		padding-top: 5px;
		margin: 0 -5px;

		line-height: 21px;
	}

		.form-tags-list-item {
			display: inline-block;
			height: 21px;
			margin-bottom: 5px;
			margin-left: 5px;

			line-height: 21px;
			vertical-align: top;
		}

		.form-tags-tag {
			position: relative;
			padding: 0 5px;
			margin-right: 5px;
			margin-left: 0;

			font-size: 15px;
			white-space: nowrap;
			cursor: pointer;

			background: $color-border-light;
			border-radius: 3px;
			transition: background-color $transition-timing, color $transition-timing;
		}

			#{$form-tags-tag-hot-selector} {
				&, &:hover {
					color: $color-white;
					background: $color-destructive;
				}
			}

			.form-tags-tag-text {
				transition: opacity $transition-timing;

				.form-tags-tag:hover & {
					opacity: 0.25;
				}
			}

			.form-tags-tag-close {
				position: absolute;
				top: 0;
				right: -2px;

				display: block;
				height: $form-tags-tag-height;
				padding: 0 5px 0 10px;
				margin: 0;
				font-size: 8px;

				line-height: $form-tags-tag-height;
				color: $color-destructive;

				background-image: linear-gradient(90deg, rgba($color-border-light, 0.0) 0%, $color-border-light 50%);
				opacity: 0.0;
				transition: opacity $transition-timing, right $transition-timing;

				.form-tags-tag:hover & {
					right: 3px;
					opacity: 1.0;
				}

				#{$form-tags-tag-hot-selector} & {
					color: $color-white;
					background-image: linear-gradient(90deg, rgba($color-destructive, 0.0) 0%, $color-destructive 50%);
				}
			}

				.form-tags-tag-close-icon {
					vertical-align: middle;
				}

	.form-tags-input, .form-tags-placeholder-input {
		display: block;
		padding: 4px 0;

		border: 0;
		border-radius: 0;
		box-shadow: none;
		transition: none;

		&:focus {
			box-shadow: none;
		}
	}

		.form-tags-placeholder-input {
			width: 100%;
			margin-top: 3px;
		}

		.form-tags-input {
			width: 1px;
			height: 100%;
			padding: 0;
		}

	.form-tags-sizer {
		position: absolute;
		top: -500px;
		left: -500px;
		z-index: -1;

		padding: 0;
		margin: 0;

		visibility: hidden;
	}

	.form-tags-hidden {
		display: none;
	}

	.form-tags-error, .form-tags-error-message {
		color: $color-state-negative;
	}

	.form-tags-disable {
		background-color: $color-icon-inactive;
	}
