.form-color-picker-container {
	position: relative;
	width: 160px;

	@extend %form-input;
}

	.form-color-picker-active {
		.form-color-picker-popover {
			pointer-events: auto;

			opacity: 1;
			transform: translateX(-50%);
		}
	}

	.form-color-picker {
		display: block;
		width: 100%;

		color: $color-white;
		text-align: center;

		background: transparent;
		border: 0;
	}

		.form-color-picker-light {
			color: $color-black;
		}

	.form-color-picker-popover {
		position: absolute !important;
		bottom: 100%;
		left: 50%;

		line-height: 1;
		pointer-events: none;

		opacity: 0;
		transform: translateY(15px) translateX(-50%);
		transition: all $transition-timing;
	}

		.form-color-picker-popover-bottom {
			top: 100%;
			bottom: auto;
			transform: translateY(-15px) translateX(-50%);
		}

	.form-color-picker-picker {
		width: 160px;
		font-size: 0;
	}

	.form-color-picker-saturation, .form-color-picker-slider {
		position: relative;
		display: inline-block;
		height: 140px;

		cursor: pointer;
		border-radius: 3px;

		&:after {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			border: 1px solid rgba($color-black, 0.15);
			border-radius: 3px;
			content: '';
		}

		svg {
			border-radius: 3px;
		}
	}

	.form-color-picker-saturation {
		width: 140px;
	}

		.form-color-picker-saturation-indicator {
			position: absolute;
			z-index: 1;

			width: 12px;
			height: 12px;
			overflow: hidden;

			border: 2px solid $color-white;
			border-radius: 6px;
			box-shadow: 0 0 0 1px rgba($color-black, 0.05), 0 1pxI 3px 0 rgba($color-black, 0.10);

			&:after {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				background-image: linear-gradient(-45deg, rgba($color-white, 0.00) 0%, rgba($color-white, 0.00) 51%, rgba($color-white, 0.15) 51%, rgba($color-white, 0.50) 100%);
				content: '';
			}
		}

	.form-color-picker-slider {
		width: 15px;
		margin-left: 5px;
	}

		.form-color-picker-slider-indicator {
			position: absolute;
			z-index: 1;

			width: 13px;
			height: 6px;
			margin-left: 1px;

			background-image: linear-gradient(-65deg, rgba($color-white, 0.00) 0%, rgba($color-white, 0.00) 51%, rgba($color-white, 0.15) 51%, rgba($color-white, 0.50) 100%);
			border-radius: 2px;
			box-shadow: 0 0 0 2px $color-white, 0 0 0 3px rgba($color-black, 0.05), 0 1px 5px 0 rgba($color-black, 0.10), 0 0 0 3px rgba($color-black, 0.05), inset 0 0 3px 0 rgba($color-black, 0.10);
		}


.form-color-picker-presets {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;

	line-height: $body-line-height;
	text-align: left;
}

	.form-color-picker-preset {
		display: block;
		width: 15px;
		height: 15px;

		cursor: pointer;
		border: 1px solid rgba($color-black, 0.15);
		border-radius: 3px;
	}
