.breadcrumbs {
	display: flex;
	align-items: center;
	text-overflow: ellipsis;

	@include clearfix;
}

	.breadcrumb-item {
		position: relative;
		display: block;
		color: $color-muted;

		&:last-child {
			min-width: 0;
			font-weight: $font-weight-light;
			color: $color-primary;
		}

		& + & {
			padding-left: 15px;
			margin-left: 15px;

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -3px;
				margin-left: -5px;
				font-family: $font-family-icons;
				font-size: 10px;
				line-height: 10px;
				color: $color-muted;
				pointer-events: none;
				content: $app-icon-chevron-right;
			}
		}
	}

		.breadcrumb-item-link, .breadcrumb-item-first {
			display: block;
			color: $color-black;
		}

		.breadcrumb-item-text {
			display: block;
			max-width: 300px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

.breadcrumbs-overflow-trigger {
	display: inline-block;
	width: 30px;
	height: 20px;
	margin-top: -3px;

	font-size: 18px;
	line-height: 19px;
	vertical-align: middle;

	background: rgba($color-card-dark, 0.2);
	border: 1px solid $color-card-dark;
	border-radius: $border-radius-size;
}

	.breadcrumbs-overflow-icon {
		color: $color-icon-dropdown;
	}
