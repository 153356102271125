.form-file {
	display: inline-flex;
	width: 100%;
	font-size: 0;
	white-space: nowrap;
}

	.form-file-input {
		display: none;
	}

	.form-file-content {
		display: none;

		@extend %form-input;
		width: auto;
		height: 32px;
		padding-top: 0;
		padding-bottom: 0;
		margin-right: 15px;
		overflow: hidden;
		font-size: $body-font-size;
		line-height: 32px;

		.form-file-active & {
			display: flex;
		}

		&::before {
			margin-right: 10px;

			font-family: $font-family-icons;
			font-size: 11px;
			color: $color-light-gray;
			content: $app-icon-mime;
		}
	}

		.form-file-content-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.form-file-remove {
			margin-left: 15px;
			font-size: 11px;
			color: $color-body-text;
		}
