.sorter {
	display: inline-block;
	white-space: nowrap;
	cursor: pointer;

	&::after {
		position: relative;
		top: -1px;

		display: inline-block;
		margin-left: 10px;

		font-family: $font-family-icons;
		font-size: 10px;
		content: $app-icon-arrows;
	}
}

	.sorter-asc, .sorter-desc {
		color: $color-body-text;

		&::after {
			top: -2px;
			margin-left: 8px;

			font-size: 8px;
			content: $app-icon-triangle-down !important;
		}
	}

	.sorter-asc {
		&::after {
			transform: scaleY(-1.0);
		}
	}
