@each $name, $color in $colors-social {
	.social-icon-#{$name} {
		@if($name == 'instagram') {
			padding: 0 0.5px; // Not ideal, but icon gets clipped without it
			background: $color-social-instagram-gradient;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		} @else {
			color: $color;
		}
	}
}

@each $name, $color in $colors-social {
	.social-link-#{$name} {
		@include link-color($color);
	}
}

$action-type-icons: (
	applause: $app-icon-heart,
	conversation: $app-icon-bubble,
	amplification: $app-icon-repost
);


.social-action-icon {
	&.colored {
		padding: 5px 4px;
		color: $color-white;
		border-radius: 5px;
	}
}

@each $name, $color in $action-type-colors {
	.app-icon-#{$name} {
		&.colored {
			background: $color;
		}
	}
}


@each $name, $icon in $action-type-icons {
	.app-icon-#{$name} {
		&:before {
			content: $icon;
		}
	}
}
