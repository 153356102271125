.filter {
	display: block;
	clear: both;
}

.filter-item {
	display: block;
	height: 34px;
	padding: 0 15px;

	line-height: 34px;
	white-space: nowrap;
	box-shadow: inset 0 1px 0 $color-border;
}

.filter-item-selected {
	background: $color-row-selected-background;
}

.filter-item-checkbox {
	display: inline-block;
	vertical-align: top;
}

.filter-item-label {
	display: inline-block;
	margin-left: 8px;
	vertical-align: middle;
}
