.impersonator {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: $header-z-index + 1000;

	display: block;
	width: 100%;
	height: 100px;

	font-size: 16px;
	line-height: 100px;
	color: $color-white;
	text-align: center;

	background: $color-primary;
}
