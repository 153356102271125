$is-story-badge-color: #616262;
$is-story-badge-bg-color: #e5e5e5;

.post-header {
	display: flex;
	align-items: center;
	height: 35px;
	margin-bottom: 10px;
}

	.post-header-link {
		display: flex;
		align-items: center;
		flex: 1;
		height: 100%;
		overflow: hidden;
		margin-right: 10px;
	}

	.post-header-avatar {
		position: relative;
	}

	.post-header-handle {
		margin: 0 5px 0 15px;
		overflow: hidden;

		color: $color-body-text;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.post-badge-is-story {
		position: absolute;
		z-index: 1;
		top: 10px;
		right: 10px;
		border-radius: 20px;
		padding: 4px 10px;
		font-size: 14px;
		color: $is-story-badge-color;
		background-color: $is-story-badge-bg-color;
	}

	.post-badge-icon-area {
		margin-left: 10px;
		display: inline-block;

		.app-icon + .app-icon {
			margin-left: 5px;
		}
	}

	.post-icon-item {
		margin-right: 3px;
	}

	.post-badge-is-story + .post-platform {
		margin-left: 4px;
	}

	.post-platform {
		display: block;
		float: right;
		color: $color-secondary-text;
	}

		.post-platform-icon {
			font-size: 20px;
		}
