.export-modal-header {
	margin-top: -15px;
}

.export-modal-header-option {
	padding: 15px;
	margin: 0 -15px;
	border-bottom: 1px solid $color-border-light;
}

	.export-modal-header-option-title {
		color: $color-muted;
	}

	.export-modal-header-option-content {
		display: inline-block;
		padding-left: 20px;
	}


.export-modal-text {
	font-size: $body-font-size;
	font-weight: $font-weight-normal;
}

.export-modal-content-input {
	height: $body-line-height * 4em;
	resize: none;
}
