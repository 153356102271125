.header-search {
	position: relative;

	display: block;
	float: left;
	width: 190px;
	height: inherit;
	padding: round(($header-height - 32px) / 2) 0;
	margin-left: 30px;
}

	.header-search-results {
		@extend %popover-closed;

		position: absolute;
		top: 41px;
		left: 7px;

		display: block;
		width: 400px;

		transform-origin: 32px top;

		.header-search-form-field:focus ~ &.header-search-results-has-content,
		&.header-search-results-has-content:hover  {
			@extend %popover-open;
		}
	}


		.header-search-results-content {
			display: block;
			max-height: calc(100vh - 70px);
			margin: -11px -10px -10px;
			overflow: auto;
		}

		.header-search-result-placeholder {
			display: block;
			padding: 5px;

			font-size: 18px;
			font-weight: 400;
			text-align: center;
		}

		.header-search-result {
			display: flex;
			align-items: center;
			padding: 12px 15px;
			line-height: 24px;
			color: $color-body-text;
			background-color: rgba($color-highlighted, 0.0);

			transition: {
				property: background-color, border-color, color;
				timing-function: $transition-timing-function;
				duration: 0.1s;
			}

			& + & {
				border-top: 1px solid $color-input-muted;
			}

			&:hover, &.header-search-result-active {
				color: darken($color-body-text, 10%);

				background-color: $color-highlighted;
				border-color: $color-border-light;

				& + .header-search-result {
					border-color: $color-border-light;
				}
			}

			&:first-child {
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
			}

			&:last-child {
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 5px;
			}
		}

			$header-search-result-icon-size: 28px;

			.header-search-result-icon {
				display: block;
				width: $header-search-result-icon-size;
				height: $header-search-result-icon-size;
				margin-right: 10px;
				vertical-align: middle;
			}

				.header-search-result-icon-avatar {
					display: block;
					width: 100%;
					height: 100%;
					background: $color-avatar-background none no-repeat center center;
					background-size: cover;
					border-radius: 50%;
					box-shadow: 0 1px 2px 0 rgba($color-black, 0.20);
				}

				.header-search-result-icon-avatar-tag {
					background-color: transparent;
					background-size: contain;
				}

				.header-search-result-icon-avatar-placeholder {
					font-size: 14px;
					font-weight: $font-weight-bold;
					line-height: $header-search-result-icon-size;
					color: darken($color-avatar-background, 60%);
					text-align: center;

					background: $color-avatar-background none no-repeat center center;
					opacity: 0.5;
				}

				.header-search-result-icon-social {
					font-size: $header-search-result-icon-size;
				}

			.header-search-result-content {
				display: block;
				flex: 1;
				overflow: hidden;

				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.header-search-result-subtitle {
				margin-left: 3px;
				opacity: 0.5;
			}

			.header-search-result-type {
				display: block;
				float: right;
				padding: 5px 5px 3px;
				margin-left: 10px;

				font-family: $font-family-header;
				font-size: 8px;
				font-weight: $font-weight-semibold;
				line-height: 1em;
				letter-spacing: 1px;
				color: $color-white;
				text-transform: uppercase;
				vertical-align: top;

				background-color: $color-muted;
				border-radius: 4px;
			}

				.header-search-result-type-influencer {
					background-color: $color-primary;
				}

				.header-search-result-type-campaign {
					background-color: $color-secondary;
				}

				.header-search-result-type-list {
					background-color: $color-affirmative;
				}

				.header-search-result-type-social {
					background-color: $color-dark-gray;
				}
