.sort-picker {
	display: inline-block;
	width: auto;
	height: inherit;

	line-height: inherit;
	vertical-align: top;
}

	.sort-picker-items {
		margin-top: 3px;
		margin-right: -11px;
	}

	.sort-picker-value {
		color: $color-link;
		white-space: nowrap;
	}

	.sort-picker-icon {
		position: relative;
		top: -1px;
		font-size: 8px;
	}

	.sort-picker-direction {
		margin-left: 3px;
		font-size: 12px;
	}

		.sort-picker-direction-icon {
			display: inline-block;
		}

		.sort-picker-direction-asc-icon {
			transform: rotate(180deg);
		}

	.sort-picker-icon-right {
		margin-left: 3px;
	}

	.sort-picker-icon-left {
		margin-right: 3px;
	}
