.form-input-addon {
	display: flex;
}

	.form-input-addon-primary {
		flex: 1;

		&#{&}-right {
			text-align: right;
		}
	}

	.form-addon {
		margin-left: 15px;
	}

	.form-addon-btn {
		margin-left: 20px;
	}
