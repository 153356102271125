%form-input {
	display: block;

	width: 100%;
	padding: 7px 15px;

	font-family: $font-family-sans-serif;
	color: $color-body-text;


	background: $color-white;
	border: 0;
	border-radius: $border-radius-size;
	box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0.0),
				0 0 0 1px rgba($color-black, 0.05),
				0 1px 3px 0 rgba($color-black, 0.1);
	transition: box-shadow $transition-timing;

	&:focus {
		box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0.1),
					0 0 0 1px rgba($color-black, 0.06),
					0 1px 2px 0 rgba($color-black, 0.1);
	}

	&::placeholder {
		color: $color-form-input-placeholder;
	}
}

.form-inline {
	display: inline;
}

.form-input {
	@extend %form-input;
}

	.form-input-container {
		display: block;
		margin-bottom: 30px;
	}

	.form-input-help {
		display: block;
		margin-top: 10px;

		font-size: 12px;
		color: $color-secondary-text;
	}

	.form-input-alert-error {
		display: block;
		padding-top: 3px;
		font-size: 12px;
		color: $color-destructive;
	}

	.form-input-label {
		display: block;
		margin-bottom: 15px;

		font-size: 15px;
		color: $color-dark-gray;
	}

	.form-select-container {
		position: relative;

		&::after {
			position: absolute;
			top: 50%;
			right: 15px;

			margin-top: -5px;

			font-family: $font-family-icons;
			font-size: 10px;
			line-height: 1;
			color: $color-muted;
			pointer-events: none;
			content: $app-icon-arrows;
		}
	}

	.form-select {
		padding-right: 40px;
		line-height: normal;

		background: $color-white;
		appearance: none;

		@extend %form-input;

		&:invalid {
			color: $color-tertiary-text;
		}
	}

	.form-select-small {
		padding: 5px 10px;
		padding-right: 42px;
		font-size: 13px;
	}

	.form-textarea {
		resize: vertical;
	}

.form-search {
	position: relative;

	display: block;
	width: 100%;
}

	.form-search-with-options {
		padding-right: 44px;
	}

	.form-search-field {
		display: block;
		width: 100%;
		height: 100%;
		padding: 7px 10px 7px 38px;

		color: $color-body-text;

		background: $color-input-muted;
		border: 0;
		border-radius: 16px;
		box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0),
					0 0 0 1px rgba($color-black, 0),
					0 1px 2px 0 rgba($color-black, 0);
		box-sizing: border-box !important;
		transition: background-color $transition-timing, box-shadow $transition-timing;

		&:focus {
			background-color: $color-white;
			border-color: $color-border;
			box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0.1),
						0 0 0 1px rgba($color-black, 0.06),
						0 1px 2px 0 rgba($color-black, 0.1);
		}

		&:invalid, &:-moz-submit-invalid, &:-moz-ui-invalid {
			box-shadow: none;
		}

	}

	.form-search-icon, .form-search-clear {
		position: absolute;
		top: 0;

		display: block;
		width: 16px;
		height: 100%;
		padding: 0;

		font-size: 16px;
		line-height: 32px;
		color: $color-icon-inactive;

		background: none;
		border: 0;
		transition: color $transition-timing;
	}

	.form-search-icon {
		left: 12px;

		.form-search-field:focus ~ & {
			color: $color-muted;
		}
	}

	.form-search-clear {
		right: 10px;

		color: darken($color-card-dark, 5%);

		pointer-events: none;
		opacity: 0.0;
		transition: color $transition-timing, opacity $transition-timing;

		&:hover {
			color: darken($color-icon-inactive, 5%);
		}

		.form-search-field:focus ~ & {
			color: $color-card-dark;
			pointer-events: auto;
			opacity: 1.0;

			&:hover {
				color: $color-icon-inactive;
			}
		}

		.form-search-field:valid ~ & {
			pointer-events: auto;
			opacity: 1.0;
		}
	}

	.influencer-export-branding-client {
		position: relative;
	}

	.influencer-export-branding-logo {
		position: absolute;
		top: 10px;
		right: 10px;
		bottom: 10px;
		left: 10px;

		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

@import 'form/group';
@import 'form/autocomplete';
@import 'form/errors';
@import 'form/tags';
@import 'form/toggle';
@import 'form/label-toggle';
@import 'form/number';
@import 'form/radio-group';
@import 'form/range';
@import 'form/color-picker';
@import 'form/addon';
@import 'form/file';
