.header-help-popover-videos {
	padding: 20px 30px;
	margin: 0 -30px;

	background: $color-body-background;
	border-top: 1px solid $help-popover-border-color;
}

	.header-help-popover-videos-list {
		display: block;
		margin: 0 -10px;
		font-size: 0;
	}

		.header-help-popover-video {
			display: inline-block;
			width: percentage(1 / 3);
			padding: 0 10px;
			font-size: $body-font-size;
			vertical-align: top;
		}

			.header-help-popover-video-image {
				display: block;
				width: 100%;
				height: auto;
				margin-bottom: 20px;
				border-radius: 6px;

				box-shadow: 0 0 0 1px rgba($color-black, 0.04), 0 1px 3px 0 rgba($color-black, 0.20);
			}

			.header-help-popover-video-title {
				color: $color-dark-gray;
			}
