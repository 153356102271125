.post-details {
	display: flex;
	align-items: center;
	padding: 11px 0 13px;
	margin: 0 20px;

	white-space: nowrap;

	border-top: 1px solid $post-border-color;
}

	.post-details-left, .post-details-right {
		flex: 1;
	}

	.post-details-right {
		text-align: right;
	}

	%post-details-item {
		display: block;
		width: auto;
		height: 17px;

		line-height: 17px;

		color: $color-light-gray;
	}

		%post-details-stacked-item {
			height: 15px;

			font-size: 13px;
			line-height: 15px;

			& + & {
				margin-top: 5px;
			}
		}

	.post-details-timestamp {
		@extend %post-details-item;
	}

		.post-details-stacked-timestamp {
			@extend %post-details-stacked-item;
		}

	.post-details-engagement, .post-details-engagement-no-metrics {
		@extend %post-details-item;

		position: relative;
		cursor: default;

		&:hover {
			.post-details-engagement-popover {
				@extend %popover-open;
			}
		}
	}

		.post-details-stacked-engagement {
			@extend %post-details-stacked-item;

			.post-media-details & {
				color: $color-white;
			}
		}

		.post-details-engagement-label {
			display: inline-block;

			line-height: inherit;
			color: $color-muted;
			vertical-align: top;

			transition: color $transition-timing;

			.post-media-details & {
				color: inherit;
			}

			.post-details-engagement:hover & {
				color: $color-body-text;

				.post-media-details & {
					color: $color-white;
				}

				.post-media-placeholder & {
					color: $color-body-text;
				}
			}

			.post-media-placeholder & {
				color: $color-light-gray;
			}
		}

		.post-details-engagement-icon {
			display: inline-block;
			height: 17px;
			padding: 0 1px;
			margin: 0 -1px 0 5px;

			font-size: 11px;
			color: $color-muted;
			vertical-align: top;

			transition: color $transition-timing;

			.post-media-details & {
				background-image: $post-details-engagement-icon-gradient;

				transition: background-image $transition-timing;

				-webkit-background-clip: text;
				background-clip: text;

				-webkit-text-fill-color: transparent;
				text-fill-color: transparent; // sass-lint:disable-line no-misspelled-properties
			}

			.post-details-engagement:hover & {
				color: $color-primary;

				.post-media-details & {
					background-image: linear-gradient(-180deg, $color-primary 0%, $color-primary 100%) !important;
				}
			}
		}

		.post-details-engagement-popover {
			@extend %popover-closed;

			position: absolute;
			right: -25px;
			bottom: 30px;

			width: 140px;
			padding: 8px 10px;

			color: $color-body-text;
			text-align: left;

			transform: scale(0.8) translateY(10px) translateX(10px);
		}

			.post-details-engagement-popover-item {
				display: flex;

				font-size: 13px;
				line-height: 15px;

				& + & {
					margin-top: 4px;
				}
			}

				.post-details-engagement-popover-type {
					flex: 1;
					margin-right: 5px;
					color: $color-form-input-placeholder;
				}
				.post-details-block {
					margin-bottom: 20px;
				 }

				.post-details-engagement-popover-value {
					overflow: hidden;

					font-weight: $font-weight-semibold;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.post-details-hint-metric-title {
					text-align: left;
					letter-spacing: 0.36px;
					color: #454545;
					opacity: 0.6;
					margin-bottom: 10px;
				}
