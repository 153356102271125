.copy-target {
	position: relative;
}

.copy-tooltip {
	top: calc(100% - 10px);
	text-align: center;
}

	.copy-tooltip-open {
		pointer-events: auto;

		opacity: 1;
		transform: scale(1) translateX(-50%);
	}

.copy-temp-target {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 0;

	width: 5px;
	height: 5px;

	font-size: 10px;
}

.copy-modal-body {
	display: block;
}

	.copy-modal-error {
		display: block;
		margin-bottom: 15px;

		color: $color-destructive;
	}

	.copy-modal-help {
		display: block;
	}

	.copy-modal-textarea {
		height: 100px;
		margin: 15px 0;
		resize: none;
	}
