.autocomplete-search-container {
	position: relative;
}

.autocomplete-avatar {
	display: inline-block;
	margin-right: 15px;

	vertical-align: middle;
}

	.autocomplete-avatar-platform {
		font-size: 23px;
		display: inline-block;
		width: 24px;
		height: 24px;
	}

.autocomplete-remove {
	display: inline-block;
	width: 16px;
	height: 16px;
	padding: 0;
	margin-left: 20px;

	font-size: 8px;
	line-height: 17px;
	text-align: center;
	vertical-align: middle;
}

.autocomplete-selected-item {
	color: $color-body-text !important;
}

.autocomplete-selected-list {
	margin-top: 20px;
}

	.autocomplete-selected-list-row {
		display: flex;
		align-items: center;

		& + & {
			margin-top: 15px;
		}
	}

		.autocomplete-selected-list-row-collapsed {
			& + & {
				margin-top: 0;
			}
		}

		.autocomplete-selected-list-row-content {
			flex: 1;
			overflow: hidden;

			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.autocomplete-selected-list-row-accessory {
			display: block;
			white-space: nowrap;
		}
