$toast-top: 150px;
$toast-right: 50px;

$toast-max-width: 300px;
$toast-min-height: 100px;

.toast {
	position: fixed;
	display: flex;
	top: $toast-top;
	right: $toast-right;
	z-index: $header-z-index + 1;

	max-width: $toast-max-width;
	min-height: $toast-min-height;

	background-color: $color-card-purple;
	border: 1px solid $color-primary-purple;
	border-left: 3px solid $color-primary-purple;

	padding: 1rem;

	box-shadow: 0px 2px 16px 0px #00000029;
	opacity: 1;

	transition: all .75s ease;

	&-error {
		background-color: $color-card-negative;
		border-color: $color-state-negative;
	}
}

.toast-info {
	color: $color-primary-purple;
	padding-right: 10px;
}

.toast-x {
	color: $color-primary-purple;
	font-size: xx-small;
	cursor: pointer;
	padding-left: 10px;

	&-error {
		color: $color-destructive;
	}
}

.toast-closed {
	opacity: 0;
}

.toast-hidden {
	display: none;
}
