.form-alerts {
	display: block;
	margin-bottom: 30px;
}

	.form-alert {
		display: block;
		padding: 10px 12px;

		color: $color-white;
		border-radius: $border-radius-size;

		+ .form-alert {
			margin-top: 10px;
		}
	}

		.form-alert-title {
			margin: 0;
			color: inherit;
		}

		.form-alert-error {
			padding: 12px 12px 10px;
			background: $color-secondary;
		}

		.form-alert-success {
			background-color: $color-primary;
		}

		.form-alert-destructive {
			background-color: $color-destructive;
		}
