.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $header-z-index;

	display: block;
	width: 100%;

	background: $color-header-background;
}

	.header-with-shadow {
		box-shadow: $header-shadow;
	}

	.header-container {
		height: $header-height;
		line-height: $header-height;
	}

	.header-logo {
		display: flex;
		float: left;
		height: inherit;
		overflow: hidden;

		font-size: 24px;
		line-height: $header-height + 2px;
		color: $color-primary-purple;

		&:hover, &:active {
			color: darken($color-primary-purple, 15%);
		}
	}

	.header-links {
		display: block;
		float: right;
		height: inherit;
	}

		.header-link {
			display: block;
			float: left;
			height: inherit;
			margin-left: 15px;

			font-size: 16px;
			color: $color-body-text;
		}

	.header-user {
		display: block;
		width: $avatar-size-large;
		height: inherit;
		margin-left: 20px;
	}

		.header-user-actions {
			width: auto !important;
			height: auto !important;
		}

			.header-user-actions-items {
				top: 100% !important;
				right: -15px !important;
			}

		.header-user-avatar {
			margin-top: round(($header-height - $avatar-size-large) / 2);
		}

	.header-help {
		position: relative;
		width: 40px;
		text-align: center;
		cursor: pointer;
	}

		.header-help-trigger {
			position: relative;
			top: -1px;

			font-size: 20px;
			color: $color-light-gray;
			transition: color $transition-timing;

			.header-help:hover &, .header-help-active & {
				color: $color-primary;
			}
		}

@import 'header/help';
@import 'header/search';
