$footer-disclaimer-text: #60636b;

.footer {
	display: block;
	width: 100%;

	color: $color-secondary-text;
	background: $color-footer-background;
}

	.footer-container {
		position: relative;
		height: $footer-height;
		padding-top: 17px;
		padding-bottom: 10px;
	}

	.footer-logo {
		display: inline-block;

		font-size: 32px;
		line-height: 22px;

		text-shadow: 0 1px 2px rgba($color-black, 0.10);

		&, &:hover, &:active {
			color: $color-primary-purple;
		}
	}

	.footer-links {
		text-align: left;
	}

		.footer-link {
			display: inline-block;

			+ .footer-link {
				margin-left: 20px;
			}

			a {
				@include link-color($color-border-light, $amount: 15%, $multiplier: 1.6);
			}
		}

	.footer-copyright {
		display: block;
		margin-top: 30px;
	}

	.footer-social-links {
		text-align: right;
		padding-top: 10px;
	}

		.footer-social-link {
			display: inline-block;

			font-size: 20px;
			line-height: 20px;

			+ .footer-social-link { margin-left: 20px; }
		}

			.footer-social-link-icon {
				display: inline-block;

				color: $color-secondary-text;
				transition: color $transition-timing;
			}

			@each $name, $color in $colors-social {
				.footer-social-link-icon-#{$name} {
					@if($name == 'linkedin') {
						position: relative;
						top: -1px;
					}

					@if($name == 'instagram') {
						padding: 0 0.5px;

						&:after {
							position: absolute;
							top: 0;
							margin-top: 10px;
							display: block;

							background: $color-social-instagram-gradient;
							content: $app-icon-social-instagram;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;

							opacity: 0;
							transition: opacity $transition-timing;
						}
					}

					&:hover {
						@if($name == 'instagram') {
							&:after {
								opacity: 1;
							}
						}

						@else if($name =='twitter') {
							&:hover {
								color: $color-social-twitter-footer-hover;
							}
						} @else {
							color: lighten($color, 10%);
						}
					}
				}
			}

	.footer-phone-number {
		display: block;
		margin-top: 52px;

		text-align: right;
	}

	.footer-address {
		display: block;
		margin-top: 10px;

		text-align: right;
	}

	.footer-disclaimer {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		padding: 9px 0 10px;

		font-size: 12px;
		color: $footer-disclaimer-text;
		text-align: center;

		border-top: 1px solid $color-dark-gray;
	}
