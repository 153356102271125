$color-influencer-status-in-communication: $color-secondary;
$color-influencer-status-in-contract: #446bcc;
$color-influencer-status-scheduled: $color-primary;
$color-influencer-status-tracking: #49c5b6;
$color-influencer-status-no-outreach: $color-dark-gray;
$color-influencer-status-declined: $color-destructive;
$color-influencer-status-archived: $color-light-gray;

$colors-influencer-status: (
	in-communication: $color-influencer-status-in-communication,
	in-contract: $color-influencer-status-in-contract,
	scheduled: $color-influencer-status-scheduled,
	tracking: $color-influencer-status-tracking,
	no-outreach: $color-influencer-status-no-outreach,
	declined: $color-influencer-status-declined,
	archived: $color-influencer-status-archived,
);
