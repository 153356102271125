.btn {
	display: inline-block;
	height: 32px;
	padding: 0 10px;

	font-size: $body-font-size;
	font-weight: $font-weight-semibold;
	line-height: 32px;

	cursor: pointer;
	border: 0;
	border-radius: $border-radius-size;

	& + & {
		margin-left: 20px;

		.btn-list & {
			margin-left: 10px;
		}
	}
}

	%btn-icon {
		display: inline-block;
		height: 17px;
		margin-right: 7px;

		font-size: 12px;
		line-height: 17px;

		vertical-align: baseline;
	}

	.btn-icon {
		@extend %btn-icon;
	}

	%btn-stateful {
		box-shadow: 0 1px 2px 0 rgba($color-black, 0.1);
	}

	.btn-action {
		@extend %btn-stateful;

		@include link-color($color-muted, $property: background-color) {
			color: $color-white;
		}
	}

	.btn-affirmative {
		@extend %btn-stateful;

		@include link-color($color-affirmative, $property: background-color) {
			color: $color-white;
		}
	}

	.btn-destructive {
		@extend %btn-stateful;

		@include link-color($color-destructive, $property: background-color) {
			color: $color-white;
		}
	}

	.btn-cancel {
		@include link-color($color-light-gray)
	}

	.btn-primary {
		@extend %btn-stateful;

		@include link-color($color-primary, $property: background-color) {
			color: $color-white;
		}
	}

	.btn-block {
		display: block;
	}

	.btn-plain {
		font-weight: $font-weight-normal;
		line-height: 32px;
		color: $color-dark-gray;

		background: $color-white;
		box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0.0),
					0 0 0 1px rgba($color-black, 0.05),
					0 1px 3px 0 rgba($color-black, 0.1);
		transition: color $transition-timing, box-shadow $transition-timing;

		.btn-icon {
			position: relative;
			top: -1px;

			height: 18px;

			font-size: 18px;
			line-height: 18px;
			color: $color-light-gray;
			vertical-align: middle;

			transition: color $transition-timing;
		}

		&:hover {
			&, .btn-icon {
				color: $color-link-hover;
			}

			box-shadow: 0 2px 4px 0 rgba($color-dark-gray, 0.1),
						0 0 0 1px rgba($color-black, 0.06),
						0 1px 2px 0 rgba($color-black, 0.1);
		}

		&:active {
			&, .btn-icon {
				color: $color-link-active;
			}

			background: $color-white;
			border-color: $color-link-active;
		}
	}

	.btn-disabled {
		cursor: not-allowed !important;
		background: $color-light-gray !important;
	}

	.btn-non-actionable {
		pointer-events: none;
	}

	.btn-link {
		padding: 0;
		margin: 0;
		text-align: left;
		cursor: pointer;

		background: none;
		border: 0;
		transition: color $transition-timing;

		&:hover { color: $color-link-hover; }
		&:active { color: $color-link-hover; }
	}

.btn-show-more {
	font-family: $font-family-sans-serif;
	font-size: $body-font-size;
	font-weight: $font-weight-semibold;
	letter-spacing: 0;
	text-transform: none;

	@include link-color($color-link);
}

	.btn-show-more-in-header {
		margin-top: -4px;
	}

	.btn-show-more-icon {
		@extend %btn-icon;

		margin-right: 4px;
	}
