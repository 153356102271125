.form-toggle {
	position: relative;

	display: inline-block;
	width: 14px;
	height: 14px;

	vertical-align: middle;

	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
}

	.form-toggle-field {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		visibility: hidden;
	}

	.form-toggle-content {
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 100%;

		font-size: 8px;
		line-height: 16px;
		color: transparent;
		text-align: center;
		vertical-align: middle;

		pointer-events: none;
		background: $color-white;
		border-radius: 2px;

		box-shadow: 0 1px 2px 0 rgba($color-black, 0.30), 0 0 0 1px rgba($color-black, 0.05), 0 1px 3px 0 rgba($color-black, 0.10);
		transition: background-color $transition-timing, box-shadow $transition-timing;

		.form-toggle-field:checked + & {
			color: $color-white;
		}
	}

	.form-toggle-radio-content, .form-toggle-switch-content {
		border-radius: 7px;
	}

	.form-toggle-checkbox-content, .form-toggle-radio-content {
		.form-toggle-field:checked + &, .form-toggle-field:indeterminate + & {
			background: $color-primary;
			box-shadow: 0 0 0 1px $color-primary;
		}

		.form-toggle-field:indeterminate + & {
			.form-toggle-radio-content-icon, .form-toggle-checkbox-content-icon {
				position: absolute;
				top: 6px;
				left: 3px;

				display: block;
				width: 8px;
				height: 2px;
				background: $color-white;
				border-radius: 1px;
			}
		}
	}

		.form-toggle-radio-indicator {
			display: inline-block;
			width: 6px;
			height: 6px;
			margin-top: 4px;

			vertical-align: top;

			background: $color-white;
			border-radius: 4px;
		}

	.form-toggle-switch {
		width: 22px;
	}

		.form-toggle-switch-knob {
			display: block;
			width: 12px;
			height: 12px;
			margin-top: 1px;
			margin-left: 0;

			background-image: linear-gradient(0deg, $color-body-background 0%, $color-white 100%);
			border-radius: 6px;
			box-shadow: 0 1px 0 0 rgba($color-black, 0.13), 0 1px 2px 0 rgba($color-black, 0.05), 0 0 0 1px rgba($color-black, 0.10), 0 2px 4px 0 rgba($color-black, 0.10);
			transition: transform $transition-timing;
		}

		.form-toggle-switch-content {
			background: $color-card-dark;
			box-shadow: inset 0 0 0 1px rgba($color-black, 0.05);

			.form-toggle-field:checked + & {
				background: $color-primary;

				.form-toggle-switch-knob {
					transform: translateX(9px);
				}
			}
		}
