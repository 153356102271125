.hidden {
	display: none;
}

$modifiers: 0, .25, .5, 1, 1.25, 1.5;

@each $modifier in $modifiers {
	$index: index($modifiers, $modifier) - 1;

	.mt-#{$index} {
		margin-top: 1rem * $modifier !important;
	}

	.mb-#{$index} {
		margin-bottom: 1rem * $modifier !important;
	}

	.m-#{$index} {
		margin: 1rem * $modifier !important;
	}

	.p-#{$index} {
		padding: 1rem * $modifier !important;
	}
}
