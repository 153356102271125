.form-radio-group {
	display: block;
}

	.form-radio-group-item {
		display: block;
		white-space: nowrap;

		& + & {
			margin-top: 4px;
		}
	}

		.form-radio-group-item-control,
		.form-radio-group-item-label {
			display: inline-block;
			vertical-align: top;
		}

		.form-radio-group-item-label {
			margin-left: 6px;
			white-space: normal;
		}
