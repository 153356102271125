.section {
	+ .section {
		margin-top: 30px;
	}
}

	.section-title {
		margin-top: 40px;
		font-weight: 400;
	}

	.section-empty-text {
		margin: 30px auto;

		font-size: $body-font-size;
		font-weight: $font-weight-normal;
		line-height: $body-line-height;
		color: $color-light-gray;
		text-align: center;
	}
