.avatar-stack {
	position: relative;
	z-index: 0;

	display: inline-block;
	font-size: 0;
	white-space: nowrap;
	vertical-align: bottom;
}

	.avatar-stack-item {
		position: relative;

		display: inline-block;
		margin-left: -5px;
		vertical-align: top;

		.avatar-stack-tight & {
			margin-left: -10px;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	@for $i from 1 through 10 {
		.avatar-stack-item:nth-child(#{$i}) {
			z-index: 10 - $i;
		}
	}

		.avatar-stack-item-more {
			background: $color-dark-gray;
			border-radius: 50%;
		}

			.avatar-stack-item-more-count {
				position: absolute;
				top: 50%;
				left: 0;

				display: block;
				width: 100%;
				height: 16px;
				margin: -8px 0 0 -1px;

				font-size: 10px;
				font-weight: $font-weight-bold;
				line-height: 16px;
				color: $color-white;
				text-align: center;

				.avatar-stack-tight & {
					left: 4px;
				}
			}


	.avatar-stack-dim {
		opacity: 0.3;
	}
