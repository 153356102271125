.flex {
	display: flex;
}

	%flex-column {
		display: block;

		& + & {
			margin-left: 30px;
		}
	}

	.flex-primary {
		@extend %flex-column;

		position: relative;
		z-index: 0;

		flex: 1;
	}

	.flex-sidebar {
		@extend %flex-column;

		width: 300px;
	}

		.flex-sidebar-flush {
			padding-top: 45px;
		}
