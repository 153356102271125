.form-group {
	padding: 30px;
}

	.form-group-row {
		& + & {
			margin-top: 30px;
		}
	}

	.form-group-help {
		margin: 10px 0 0;

		font-size: 12px;
		font-weight: $font-weight-normal;
		line-height: 18px;
		letter-spacing: 0;
		color: $text-muted;
	}

	.form-group-actions {
		padding: 20px 30px;
		margin: 30px -30px -30px;
		text-align: right;
		border-top: 1px solid $color-border;
	}

	.form-group-list {
		margin: -30px;
	}

		.form-group-list-item {
			& + & {
				border-top: 1px solid rgba($color-border, 0.75);
			}
		}

			.form-group-list-item-container {
				display: flex;
				align-items: center;
				padding: 15px;
			}

			.form-group-list-item-input {
				display: block;
			}

			.form-group-list-item-content {
				display: block;
				flex: 1;
				margin-left: 15px;

				line-height: 20px;
			}

				.form-group-list-item-content-title {
					display: block;
				}

				.form-group-list-item-content-description {
					display: block;
					font-size: 13px;
					color: $color-muted;
				}
