$table-cell-horizontal-padding: 20px;

.table {
	width: 100%;

	background: $block-background;
	border: 0;
	border-collapse: separate;
	border-radius: $block-border-radius;
	box-shadow: $block-box-shadow;
}

	.table-nested {
		background: transparent;
		border-radius: 0;
		box-shadow: none;
	}

th, td {
	padding: 11px $table-cell-horizontal-padding;
	text-align: center;
	vertical-align: middle;

	&:first-child {
		text-align: left;
	}
}

th {
	font-weight: $font-weight-normal;
	color: $color-muted;

	border-bottom: 1px solid $color-card-dark;
	box-shadow: 0 1px 0 rgba($color-card-dark, 0.2);
}

tr {
	& + & {
		td {
			border-top: 1px solid rgba($color-border, 0.5);
		}
	}
}

	.table-small {
		th {
			padding: 8px 10px;
			font-size: 13px;
		}

		td {
			padding: 8px 10px;
		}
	}

	.table-condensed {
		th, th {
			padding: 11px 15px;
		}
	}

.table-link {
	color: inherit;
}

.table-line {
	display: block;
	margin: 0;

	line-height: 20px;
}

	.table-subline {
		font-size: 13px;
		color: $color-muted;
	}

.table-actions {
	width: 24px;
	max-width: 24px !important;
	min-width: 24px !important;
	padding-left: 0 !important;

	font-size: 18px;
	color: $color-muted;

	.action-menu, .action-menu-trigger {
		width: 4px;

		.action-menu-icon {
			position: relative;
			left: -6px;
		}
	}

	.action-menu-icon {
		display: inline-block;
		transform: rotate(90deg);
	}

	.action-menu-items-right {
		right: -32px;
	}

	.action-menu-items-bottom {
		bottom: 26px;
	}
}

.table-avatar {
	width: 0;
	padding-right: 0;
}

	.table-avatar-tooltip {
		left: $table-cell-horizontal-padding  + ($avatar-size-large / 2);
		margin-bottom: 0;
	}

	.table-avatar-tooltip-status {
		margin-left: 4px;
	}

.table-progress {
	width: 130px;
	padding-left: 0;
}

.table-input {
	width: 100%;
}

.table-row-highlight {
	td {
		background: $color-highlighted;
	}

	&:last-child {
		td {
			&:first-child {
				border-bottom-left-radius: $block-border-radius;
			}

			&:last-child {
				border-bottom-right-radius: $block-border-radius;
			}
		}
	}
}

.table-layout-fixed {
	table-layout: fixed;
}
