.empty {
	padding: 70px;
	text-align: center;
}

	.empty-icon {
		margin-bottom: 30px;
		font-size: 60px;
		color: $color-light-gray;
	}

	.empty-title, .empty-message {
		max-width: 400px;
		margin: 0 auto;
	}

	.empty-title {
		color: $color-muted;
	}

	.empty-message {
		margin-top: 5px;
		font-size: $body-font-size;
		font-weight: $font-weight-normal;
		line-height: $body-line-height;
		color: $color-light-gray;
	}

	.empty-btn {
		margin-top: 30px;
	}
