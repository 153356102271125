$track-color: $color-card-dark !default;

$thumb-size: 18px !default;
$track-width: 100% !default;
$track-height: 4px !default;
$track-shadow: inset 0 0 0 1px rgba($color-black, 0.05);

@mixin form-range-track() {
	width: $track-width;
	height: $track-height;

	cursor: pointer;
	transition: all 0.2s ease;
}

@mixin form-range-track-full() {
	@include form-range-track();

	background: $track-color;
	border: 0;
	border-radius: $track-height / 2;
	box-shadow: $track-shadow;
}

@mixin form-range-thumb() {
	width: $thumb-size;
	height: $thumb-size;

	cursor: pointer;

	background: $color-white;
	background-image: linear-gradient(-180deg, $color-white 0%, $color-card 100%);
	border: 0;
	border-radius: $thumb-size / 2;
	box-shadow: 0 1px 0 0 rgba($color-black, 0.13), 0 1px 2px 0 rgba($color-black, 0.05), 0 0 0 1px rgba($color-black, 0.10), 0 2px 4px 0 rgba($color-black, 0.10);
}

.form-range {
	@extend %form-input;

	width: $track-width;
	padding: 0;
	margin: $thumb-size / 2 0;

	border: 0;
	appearance: none;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		@include form-range-track-full();
	}

	&::-webkit-slider-thumb {
		@include form-range-thumb();

		margin-top: ($track-height - $thumb-size) / 2;
		appearance: none;
	}

	&::-moz-range-track {
		@include form-range-track-full();
	}

	&::-moz-range-thumb {
		@include form-range-thumb();
	}

	&::-ms-track {
		@include form-range-track();

		color: transparent;

		background: transparent;
		border-color: transparent;
		border-width: ($thumb-size / 2) 0;
	}

	&::-ms-fill-lower, &::-ms-fill-upper {
		border: 0;
		border-radius: $track-height;
		box-shadow: $track-shadow;
	}

	&::-ms-thumb {
		@include form-range-thumb();

		margin-top: 0;
	}

	&::-ms-fill-lower, &:focus::-ms-fill-lower,
	&::-ms-fill-upper, &:focus::-ms-fill-upper {
		background: $track-color;
	}

}
