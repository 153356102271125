.influencer-card {
	display: flex;
	align-items: center;
	max-width: 448px;
}

	.influencer-card-avatar {
		display: block;
		margin-right: 15px;

		.influencer-card-xl & {
			margin-right: 30px;
		}
	}

	.influencer-card-details {
		display: block;
		flex: 1;
		min-width: 0;
		overflow: hidden;
		line-height: 17px;
	}

	.influencer-card-tagline, .influencer-card-link {
		display: block;
		width: 100%;
		padding: 3px 0;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.influencer-card-tagline {
		height: 17px;
		margin-bottom: -2px;

		line-height: 11px;

		.influencer-card-xl & {
			margin-bottom: 0;
		}
	}

	.influencer-card-link {
		display: inline;
		color: inherit;
	}

	.influencer-card-icon-area {
		margin-left: 10px;

		.app-icon + .app-icon {
			margin-left: 5px;
		}
	}

	.influencer-card-cell {
		display:inline-block;
		overflow:hidden;
		text-overflow:ellipsis;
		width:68px;
	}
