.form-label-toggle {
		display: inline-block;
		cursor: pointer;

		& + & {
			margin-left: 8px;
		}
	}

		.form-label-toggle-input {
			display: none;

			&:checked + .form-label-toggle-content {
				color: $color-white;
				background: $color-primary;

				border-color: $color-primary;
			}
		}

		.form-label-toggle-content {
			display: inline-block;
			padding: 5px 10px;

			line-height: 1;
			color: $color-icon-inactive;

			border: 1px solid $color-icon-inactive;
			border-radius: 16px;
			transition: background $transition-timing, border $transition-timing, opacity $transition-timing;

			@include screen-scale-gte(2.0) {
				padding-top: 5.5px;
				padding-bottom: 5.5px;
			}
		}

			.form-label-toggle-content-large {
				padding: 8px 20px;
			}
