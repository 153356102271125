.form-number {
	position: relative;
	display: inline-block;
}

	.form-number-block {
		display: block;
	}

	.form-number-field {
		padding-right: 40px;
	}

	.form-number-modifier {
		position: absolute;
		right: 0;

		display: block;
		width: 25px;
		height: 16px;
		padding: 0;
		margin: 0;

		font-size: 10px;
		line-height: 16px;
		text-align: center;

		cursor: pointer;
		background: none;
		border: 0;
		border-left: 1px solid $color-border-light;

		@include link-color($color-light-gray);
	}

		.form-number-modifier-increment {
			top: 1px;
			transform: scale(1, -1);
		}

		.form-number-modifier-decrement {
			bottom: 1px;
		}
