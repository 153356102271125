.progress {
	display: block;
	height: 6px;
	background: $color-border-light;
	border-radius: 3px;
}

	.progress-bar {
		display: block;
		width: 0;
		height: inherit;

		background: $color-secondary;
		border-radius: 3px;
	}
