p {
	font-size: 22px;
	font-weight: $font-weight-light;
}

	.paragraph {
		font-size: $body-font-size;
		font-weight: $font-weight-normal;

		& + & {
			margin-top: 15px;
		}
	}

h1 {
	font-size: 36px;
	font-weight: $font-weight-semibold;
}

h2 {
	font-size: 21px;
	font-weight: $font-weight-normal;
	line-height: 31px;
}

h3 {
	margin: 0 0 20px;
	font-size: 16px;
	font-weight: $font-weight-semibold;
	color: $color-body-text;
}

h4 {
	margin: 0 0 30px;
}

h4, .h4 {
	display: block;

	font-family: $font-family-header;
	font-size: 12px;
	font-weight: $font-weight-semibold;
	letter-spacing: 0.92px;
	text-transform: uppercase;
}

h5 {
	margin: 0 0 20px;

	font-size: 11px;
	font-weight: $font-weight-semibold;
	letter-spacing: 1.5px;
	color: $color-tertiary-text;
	text-transform: uppercase;
}

h6, .h6 {
	margin: 0 0 3px;

	font-family: $font-family-header;
	font-size: 10px;
	font-weight: $font-weight-semibold;
	line-height: 11px;
	letter-spacing: 1px;
	color: $color-muted;
	text-transform: uppercase;
}

.heading-accessory {
	display: inline-block;
	float: right;
	vertical-align: top;
}

.heading-collapsed {
	margin: 0;
}

.heading-inline {
	display: inline-block;
	width: auto;
	margin: 0;
}

.heading-link {
	color: inherit;
}

	.heading-link-icon {
		font-size: 11px;
	}

small {
	font-size: percentage(13 / 15);
}

strong {
	font-weight: 700;
}

code {
	font-family: $font-family-mono;
}

.text-left {
	text-align: left;
}

.text-centered, .text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-nowrap {
	white-space: nowrap;
}

.text-break {
	word-break: break-word;
}

.text-primary {
	color: $color-primary;
}

.text-secondary {
	color: $color-secondary-text;
}

.text-muted {
	color: $color-light-gray;
}

.text-success {
	color: $color-state-success;
}

.text-state-positive, .text-affirmative {
	color: $color-state-positive;
}

.text-state-neutral {
	color: $color-state-neutral;
}

.text-state-warning {
	color: $color-state-warning;
}

.text-state-negative, .text-destructive {
	color: $color-state-negative;
}

.text-difference-positive {
	color: $color-difference-positive;
}

.text-difference-negative {
	color: $color-difference-negative;
}

.text-selectable {
	user-select: text !important;
}

.color-non-vetted {
	color: $color-icon-non-vetted;
}

.subtitle {
	color: $color-muted;
	font-size: 14px;
	margin: {
		top: -10px;
		bottom: 30px;
	}
}
