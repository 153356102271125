$post-border-color: rgba($color-light-gray, 0.2);

$post-media-overlay: linear-gradient(-180deg,
	rgba($color-post-media-overlay, 0.0) 0%,
	rgba($color-post-media-overlay, 0.0) 50%,
	$color-post-media-overlay 100%
);

$post-details-engagement-icon-start-color: #dddfe4;
$post-details-engagement-icon-end-color: $color-light-gray;
$post-details-engagement-icon-gradient: linear-gradient(-180deg,
	$post-details-engagement-icon-start-color 0%,
	$post-details-engagement-icon-start-color 2%,
	$post-details-engagement-icon-end-color 100%
);

.post-container {
	display: block;
	width: percentage(1 / 3);
	padding: 0 15px;
	margin-bottom: 40px;
	font-size: $body-font-size;
}

.post-caption {
	position: relative;
	padding: 15px 20px;
	font-size: 16px;
	line-height: 24px;
	word-wrap: break-word;
}

	.post-caption-link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

@import 'post/header';
@import 'post/details';
@import 'post/media';
