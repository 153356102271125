@import 'variables/colors';

$body-font-size: 15px;
$body-line-height: 1.428571429;

$border-radius-size: 5px;

$font-weight-thin: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-family-sans-serif: app-sans-serif, sans-serif;
$font-family-mono: app-mono, monospace;
$font-family-header: app-header, sans-serif;
$font-family-icons: julius-icons;

$transition-duration: 0.2s;
$transition-timing-function: ease-in-out;
$transition-timing: $transition-duration $transition-timing-function;

$header-height: 60px;
$header-margin-bottom: 50px;
$header-z-index: 10000;
$header-shadow: 0 1px 1px 0 rgba($color-black, 0.06);

$navigation-bar-height: 62px;

$section-bar-height: 57px;

$footer-height: 209px;
$footer-margin-top: 60px;

$avatar-size: 30px;
$avatar-size-small: 26px;
$avatar-size-large: 40px;
$avatar-size-xl: 64px;

$block-background: $color-white;
$block-border-radius: $border-radius-size;
$block-box-shadow: 0 0 0 1px rgba($color-black, 0.05), 0 1px 3px 0 rgba($color-black, 0.10);
$block-header-background: rgba($color-border, 0.2);
$block-header-border: $color-border;

$pdf-portrait-width: 612px;
$pdf-portrait-height: 792px;
$pdf-landscape-width: 864px;
$pdf-landscape-height: 648px;
$pdf-presentation-width: 1152px;
$pdf-presentation-height: $pdf-landscape-height;

$tooltip-box-shadow:
					0 2px 4px 0 rgba($color-black, 0.2),
					0 0 0 1px rgba($color-black, 0.05),
					0 1px 3px 0 rgba($color-black, 0.1);
