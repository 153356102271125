$section-bar-item-height: 32px;

.section-bar {
	display: block;
	height: $section-bar-height;

	font-size: 14px;
	line-height: $section-bar-height;

	background: $color-white;
	box-shadow: $header-shadow;
}

.section-bar-dark {
	background: $color-dark-gray;
	box-shadow: none;

	.section-bar-item-link {
		@include link-color($color-dark-gray, $mode: 'darken', $amount: 20%);
	}
}

.section-bar-items {
	display: block;
	height: $section-bar-height;
	padding: round(($section-bar-height - $section-bar-item-height) / 2) 0;
	margin-left: -6px;

	line-height: $section-bar-item-height;
}

.section-bar-item {
	float: left;

	&:first-child {
		margin-left: 0;
	}

	+ .section-bar-item {
		margin-left: 6px;
	}
}

.section-bar-item-link {
	display: block;
	float: left;
	padding: 0 7px;

	@include link-color($color-dark-gray, $mode: 'darken', $amount: 20%);
}

.section-bar-item-link-active {
	font-weight: $font-weight-semibold;

	background: $color-primary;
	border-radius: $border-radius-size;

	&, &:hover, &:active { color: $color-white; }
}
